module.exports = new Promise((resolve, reject) => {
      const remoteUrl = 'https://consorcio30-hs-mfelead.teste.newm.dev.br//remoteEntry.js';
      const script = document.createElement('script');
      script.src = remoteUrl;
      script.type = 'text/javascript';
      script.async = true;
			script.onerror = () => {
  		console.error('Failed to load Leads Module');
  		reject(new Error('Failed to load Leads Module'));
			};
      script.onload = () => {
				const proxy = {
          get: (request) => window.leads.get(request),
          init: (arg) => {
            try {
              return window.leads.init(arg)
            } catch(e) {
              console.log('remote container already initialized')
            }
          }
        }
        resolve(proxy);
      };
      document.head.appendChild(script);
    });